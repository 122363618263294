:root {
      /** primary **/
    --color-primary: #3880ff;
    --color-primary-rgb: 56, 128, 255;
    --color-primary-contrast: #ffffff;
    --color-primary-contrast-rgb: 255, 255, 255;
    --color-primary-shade: #3171e0;
    --color-primary-tint: #4c8dff;
    --color-primary-placeholder: #989da5;

    /** secondary **/
    --color-secondary: #3dc2ff;
    --color-secondary-rgb: 61, 194, 255;
    --color-secondary-contrast: #ffffff;
    --color-secondary-contrast-rgb: 255, 255, 255;
    --color-secondary-shade: #36abe0;
    --color-secondary-tint: #50c8ff;

     /** success **/
    --color-success: #2dd36f;
    --color-success-rgb: 45, 211, 111;
    --color-success-contrast: #ffffff;
    --color-success-contrast-rgb: 255, 255, 255;
    --color-success-shade: #28ba62;
    --color-success-tint: #42d77d;

    /** warning **/
    --color-warning: #ffc409;
    --color-warning-rgb: 255, 196, 9;
    --color-warning-contrast: #000000;
    --color-warning-contrast-rgb: 0, 0, 0;
    --color-warning-shade: #e0ac08;
    --color-warning-tint: #ffca22;

    /** error **/
    --color-error: #eb445a;
    --color-error-rgb: 235, 68, 90;
    --color-error-contrast: #ffffff;
    --color-error-contrast-rgb: 255, 255, 255;
    --color-error-shade: #cf3c4f;
    --color-error-tint: #ed576b;
}