* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, Ariel;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Rubik;
}

.fa-icon{
  color: white;
}

.fa-icon:hover {
  color: var(--color-primary)
 }

.fs-7 {
  font-size: .75em;  
}


/* Charts */

.large-chart-container{
  height: 300px !important;
}

.medium-chart-container{
  height: 200px!important;
}

@media screen and (min-width: 768px) {
  .large-chart-container{
    height: 300px !important;
  }

  .medium-chart-container{
    height: 200px!important;
  }
}

.dropdown-toggle-no-carret::after {
  display:none;
}

/*.circle {
  width: 40px;
  height: 40px;
  background-color: var(--color-primary);
  border-radius: 50%;
}*/

/* Login 

.login {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    text-align: center;
    color: var(--color-primary);
    min-width: 300px;
    justify-content: center;
}

.login label {
  text-align: left;
  padding: 0.25rem 0;
  color: var(--color-primary);
}

.login input {
  margin: 0.5rem 0;
  padding: 1rem;
  border: 0.5px solid var(--color-primary);
  border-radius: 8px;
  color: var(--color-primary);
}

.login ::placeholder {
  color: var(--color-primary-placeholder);
}

.login button {
  border: none;
  background-color: var(--color-primary);
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
}

.login .link-btn {
  background: none;
  color: var(--color-primary);
  text-decoration: underline;
}

.login .login-btn {
  border: none;
  background-color: var(--color-primary);
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  color: white;
  margin-top: 20px;
}

.login input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  -webkit-text-fill-color: var(--color-primary) !important;
}

.auth-form-container, .login-form, .register-form {
  display: flex;
  flex-direction: column;
  width: 90%;
  align-self: center;
}

@media screen and (min-width: 768px) {
  .auth-form-container {
    padding: 5rem;
    border: 1px solid var(--color-primary);
    border-radius: 10px;
  }
}

@media screen and (min-width: 768px) {
  .auth-form-container, .login-form, .register-form {
    max-width: 500px;
  }
}
*/

.wrapper {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  top: 120px;
  left:0px;
}

.content {
  flex: 1;
  padding: 0rem;
  padding-top: 0.5rem;
}

@media screen and (min-width: 768px) {
  .content {
    padding: 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  #loginCard {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .wrapper {
    left:250px;
    top: 64px;
    width: calc(100% - 250px);
  }
}

.sidebar {
  position: fixed;
  max-width: 250px;
  min-width: 250px;
  margin-left:  -250px;
  top: 120px;
  transition: margin-left .3s ease-in-out,left .3s ease-in-out,margin-right .3s ease-in-out,right .3s ease-in-out;
  box-shadow: 0 0 2rem 0 rgba(0,0,0,.05);
  z-index: 900;
  height: calc(100% - 120px);
  overflow: auto;
}

.sidebar.toggled {
  margin-left:  0px;
}

@media only screen and (min-width: 768px) {
  .sidebar {
      margin-left: 0px;
      top: 64px;
      height: calc(100% - 64px);
  }
}

@media screen and (min-width: 768px) {
  .navbar-toggler {
    display: none;
  }
}

.icon {
  background: url('logo.svg');
  height: 20px;
  width: 20px;
  display: block;
  /* Other styles here */
}

.rounded-20pc {
  border-radius: 20%;
}

.dropdown-item {
  border-bottom: 1px solid lightgray;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.table1 tr:nth-child(4n) td, .table1 tr:nth-child(4n-1) td{
  background-color: rgba(0, 0, 0, 0.075)
}

.topbar {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.property_dropdown .menu {
  max-height: min(calc(55vh - 64px));
  /*min-height: 165px;*/
  overflow-y: auto;
  min-width: 300px;
} 

.condition_dropdown .menu {
  max-height: min(calc(55vh - 64px));
  /*min-height: 165px;*/
  overflow-y: auto;
  min-width: 100px;
  text-align: center;
} 

/*@media (prefers-color-scheme: dark) {
  :root {

  }

}*/

/* Top bar 

.top-bar {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f2f2f2;
  height: 60px;
  background-color: var(--color-primary-contrast);
  padding-left: 1rem;
  padding-right: 1rem;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.menu-icon span {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #333;
  margin-bottom: 4px;
  transition: background-color 0.3s ease;
}

.menu-icon span:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .menu-icon {
    display: none;
  }
}

.top-bar .title {
  font-size: 0.7rem;
}

.logout-button button {
  border: none;
  background-color: var(--color-primary);
  border-radius: 8px;
  cursor: pointer;
  color: white;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem
}*/

/* Side bar */

/*
.sidebar {
  position: fixed;
  height: calc(100% - 60px);
  width: 300px;
  left: -300px;
  top: 60px;
  background-color: var(--color-primary);
  transition: 0.5s ease;
  z-index: 1000;
  box-shadow: none;
  overflow: auto;
}

.sidebarList {
  height: auto;
  padding: 0;
  width: 100%;
}

.sidebarList .row a {
  width: 100%;
  height: 60px;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: left;
  color: var(--color-primary-contrast);
}

.sidebarList .row:hover {
  cursor: pointer;
  background-color: var(--color-primary-shade);
  color: white
}

.sidebarList .row #icon {
  flex: 30%;
  display: grid;
  place-items: center;
}

.sidebarList .row #title {
  flex: 70%;
}

.sidebarList .row .active {
  background-color: var(--color-primary-contrast);
  color: var(--color-primary);
}

.sidebar.active {
  left: 0px;
  box-shadow: 0px 0px 8px rgba(0,0,0,.6);
}

@media screen and (min-width: 768px) {
  .sidebar {
    left: 0px;
    box-shadow: none;
  }
}*/

/* Main content 

.main_content {
  position: fixed;
  left: 0px;
  transition: 0.5s ease;
  top: 60px;
  height: calc(100% - 60px);
  width: 100%;
  overflow: auto;
}

@media screen and (min-width: 768px) {
  .main_content {
    left: 300px;
    width: 100%;
  }
}*/

/* Dashboard 

.dashboard {
 
    background-color: var(--color-success);
}*/

/* Admin 

.admin {
  display: flex;
  flex: 1;
  background-color: var(--color-success);
}*/

/* User 

.user {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary-contrast);
}

.userProfileImage {
  margin-top: 1rem;
}

.userProfileDate {
  margin-top: 1rem;
}*/


/* Date picker 

.datePickerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.datePicker {  
  height: 30px;
  border-radius: 8px;
  margin-left: 0.5rem;
  margin-right: 1rem;
} */

/* Global settings */

/*@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 1rem;
  }
}*/

.header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tableContainer{
  width: calc(100vw - 60px);
  
  max-height: 700px;
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .tableContainer{
    width: calc(100vw - 360px);
  }
}

.bg-header-table {
  background-color:  #dee2e6 !important;
}

.bg-header-table2 {
  background-color:  #e9ecef !important;
}

.sticky-col {
  position: sticky;
  background-color: white;
  
}

.first-col {
  min-width: 200px;
  left: 0px;
}


.table-col {
  min-width: 120px;
}

.tableSearchAds tr:nth-of-type(1) th {
  border: 0px;
  outline: 1px solid #dee2e6;
}

.tableSearchAds tr:nth-of-type(2) th {
  border: 0; 
  outline: 0.5px solid #e9ecef;
}

.tab-content .tab-pane {
  background-color: #FFF;
  color: #0080FF;
  min-height: 200px;
  height: auto;
}

/*.arrow-right:after {
  content: "";
  display: inline-block !important;
  width: 0;
  height: 0;
  border-left: 8px solid #000000;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  vertical-align: middle;
}

.arrow-right:before {
  width: 120px;
  height: 2px;
  background: #000000;
  content: "";
  display: inline-block;
  vertical-align: middle;
}*/

.arrow-right {
  position:relative;
  width:120px;
  margin:5px auto;
  height:5px;
  border-bottom:2px solid rgb(0, 0, 0);
}

.arrow-right::after { 
  content: '';
  width: 0; 
  height: 0; 
  border-left: 8px solid #000000;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  position: absolute;
  right: -5px;
  top: -4px;
}

.arrow-right2 {
  position:relative;
  width:439px;
  margin:5px auto;
  height:5px;
  border-bottom:2px solid rgb(0, 0, 0);
}

.arrow-right2::after { 
  content: '';
  width: 0; 
  height: 0; 
  border-left: 8px solid #000000;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  position: absolute;
  right: -5px;
  top: -4px;
}

.arrow-top:before {
  width: 2px;
  height: 37px;
  background: #000000;
  content: "";
  display: inline-block;
}