.custom-json-pretty {
  background: #333;
  line-height: 1.3;
  color: rgba(248,248,242,1);
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  border-radius: 6px;
  padding: 15px 10px 15px 10px ;
  max-height: 600px;
  overflow: auto;
  max-width: calc(100vw - 60px);

  .__json-key__ {
    color: #FFFFFF;
    line-height: 1.3;
    color: rgba(248,248,242,1);
    overflow: auto;
  }

  .__json-key__ {
    color: #FFFFFF;
  }

  .__json-value__ {
    color: rgb(211, 99, 99);
  }

  .__json-string__ {
    color: rgb(162, 252, 162);
  }

  .__json-boolean__ {
    color: rgb(252, 194, 140);
  }
}

.__json-pretty-error__ {
  line-height: 1.3;
  color: rgba(248,248,242,1);
  background: #1e1e1e;
  overflow: auto;

  .__json-value__ {
    color: #A6E22E;
  }

  .__json-string__ {
    color: #FD971F;
  }

  .__json-boolean__ {
    color: #AC81Fe;
  }
}

@media only screen and (min-width: 768px) {
  .custom-json-pretty {
    width: calc(100vw - 360px);
  }
}

.style-json-pretty {
  font-size: 0.86em;  
}